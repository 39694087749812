import React from 'react'
import {
  Modal,
  Image
} from 'react-bootstrap'

function QRModal({ showQRModal, setShowQRModal }) {
  const handleClose = () => setShowQRModal(false)

  return (
    <Modal
      size="md"
      show={showQRModal}
      onHide={handleClose}
      centered
    >
      <Modal.Body>
        <Image src={`${process.env.PUBLIC_URL}/assets/ngsas-qr.jpg`} thumbnail onClick={handleClose} />
      </Modal.Body>
    </Modal>
  )
}

export default QRModal
