import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { database } from '../../firebase'
import { useAuth } from "../../contexts/AuthContext"
import {
  Form,
  Button
} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faHistory
} from '@fortawesome/free-solid-svg-icons'
import StatusLogsModal from '../modals/StatusLogsModal'

export default function StatusFormGroup({
  dashboardType,
  listItem,
  listItemData,
  listState,
  setListState
}) {
  const customerRef = database.customers.doc(listItemData.idNumber)
  const listItemRef = customerRef.collection(dashboardType).doc(listItem.id)
  const { currentUser } = useAuth()
  const [isFirstTime, setIsFirstTime] = useState(true)
  const [statusLogs, setStatusLogs] = useState([])
  const [status, setStatus] = useState('pending')
  const [showStatusLogsModal, setShowStatusLogsModal] = useState(false)

  const states = useMemo(() => ({
    pending: 'Pendiente',
    inProcess: 'En Proceso',
    ...(dashboardType === 'purchases' && {
      pendingForAdditionalInfo: 'Pendiente de información adicional',
      ready: 'Listo y esperando despacho'
    }),
    shipped: 'Enviado',
    ...(dashboardType === 'quotes' && {
      contact: 'Contactar al Cliente',
      approved: 'Aprobado por el Cliente',
      rejected: 'Rechazado por el Cliente'
    }),
    archived: 'Archivado'
  }), [listItemData])

  useEffect(() => {
    listItemRef.collection('statusLogs').orderBy('timeStamp', 'asc').get().then((querySnapshot) => {
      setStatusLogs(querySnapshot.docs)
      if (isFirstTime) {
        setStatus(querySnapshot.docs.at(-1).data().status)
        setIsFirstTime(false)
      }
    }).catch((error) => {
      console.log(`Error getting ${listItem.id} statusLogs:`, error)
    })
  }, [])

  // useEffect(() => {

  // }, [])

  const handleStateChange = useCallback((e) => {
    const status = e.target.value
    const statusLog = {
      status: status,
      timeStamp: database.getCurrentTimestamp(),
      user: currentUser.email
    }

    listItemRef.collection('statusLogs').add(statusLog).then((statusLogRef) => {
      console.log('statusLogs successfully written!', statusLogRef)

      listItemRef.update({
        'status': status
      }).then(() => {
        console.log(`Status of ${listItemRef.id} from ${listItemData.idNumber} successfully updated!`)

        if (dashboardType === 'purchases') {
          database[dashboardType].orderBy('createdAt', 'desc').get().then((querySnapshot) => {
            setListState(querySnapshot.docs)
          }).catch((error) => {
            console.log(`Error getting all ${dashboardType}:`, error)
          })
        }

      }).catch((error) => {
        console.log(`Error updating status of ${listItemRef.id} from ${listItemData.idNumber}:`, error)
      })

      setStatus(status)
    }).catch((error) => {
      console.error("Error writing statusLogs: ", error)
    })
  }, [currentUser.email, listItemRef])

  return (
    <Form.Group className="d-flex align-items-center mb-0">
      <Form.Control
        as="select"
        size="sm"
        value={status}
        onChange={handleStateChange}
        custom
      >
        {Object.keys(states).map((keyName, i) => (
          <option key={`option${i}`} value={keyName}>{states[keyName]}</option>
        ))}
      </Form.Control>
      <Button size="sm" variant="ng-primary" className="ml-2" onClick={() => setShowStatusLogsModal(true)}>
        <FontAwesomeIcon icon={faHistory} />
      </Button>
      <StatusLogsModal
        showStatusLogsModal={showStatusLogsModal}
        setShowStatusLogsModal={setShowStatusLogsModal}
        listItemData={listItemData}
        statusLogs={statusLogs}
      />
    </Form.Group>
  )
}
