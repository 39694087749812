import React from 'react'
import {
  Modal,
  Button,
} from 'react-bootstrap'

function ResetModal({
  showResetModal,
  setShowResetModal,
  handleReset
}) {
  const handleClose = () => {
    setShowResetModal(false)
  }

  return (
    <Modal
      show={showResetModal}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          Borrar Formulario
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Estas seguro que deseas borrar todo el formulario?
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
        <Button variant="ng-secondary" onClick={handleReset}>Borrar</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ResetModal
