import React from 'react'

import formInitialState from '../constants/purchaseFormInitialState'
import validationSchema from '../constants/purchaseValidationSchema'
import NgForm from './NgForm'

const LOCAL_STORAGE_KEY = 'ngSalesApp.purchaseFormState'

export default function PurchaseForm() {
  return (
    <NgForm
      formType="purchase"
      formInitialState={formInitialState}
      validationSchema={validationSchema}
      localStorageKey={LOCAL_STORAGE_KEY}
    />
  )
}
