import React from 'react'
import { useField, ErrorMessage } from 'formik'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import DropdownButton from './DropdownButton'

function TypeaheadField({
  name,
  placeholder,
  value,
  options,
  labelKey,
  formik,
  onChange
}) {
  const [field, meta] = useField(name)

  return (
    <>
      <Typeahead
        name={name}
        id={name}
        options={options}
        labelKey={labelKey}
        placeholder={placeholder}
        key={value}
        defaultSelected={[formik.values[name]]}
        inputProps={{
          name: name,
          autoComplete: "new-password",
          className: meta.touched && (meta.error ? 'is-invalid' : 'is-valid')
        }}
        onChange={onChange}
      >
        {({ isMenuShown, toggleMenu }) => (
          <DropdownButton
            isOpen={isMenuShown}
            onClick={e => toggleMenu()}
          />
        )}
      </Typeahead>
      <ErrorMessage component="div" name={field.name} className="Error-message" />
    </>
  )
}

export default TypeaheadField
