import React from 'react'

import formInitialState from '../constants/quoteFormInitialState'
import validationSchema from '../constants/quoteValidationSchema'
import NgForm from './NgForm'

const LOCAL_STORAGE_KEY = 'ngSalesApp.quoteFormState'

export default function QuoteForm() {
  return (
    <NgForm
      formType="quote"
      formInitialState={formInitialState}
      validationSchema={validationSchema}
      localStorageKey={LOCAL_STORAGE_KEY}
    />
  )
}
