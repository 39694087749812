const returnFormInitialState = {
  returnFirstName: '',
  returnMiddleName: '',
  returnLastName: '',
  returnPhone: '',
  email: '',
  emailOption: true,
  idType: 'CC',
  idNumber: '',
  bank: 'Bancolombia',
  accountNumber: '',
  accountType: 'Ahorros',
  details: '',
  imageFiles: 0,
  termsAndConditions: false,
  createdAt: '',
  isMobile: false,
  counterId: ''
}

export default returnFormInitialState