import React from 'react'

import formInitialState from '../constants/maintenanceFormInitialState'
import validationSchema from '../constants/maintenanceValidationSchema'
import NgForm from './NgForm'

const LOCAL_STORAGE_KEY = 'ngSalesApp.maintenanceFormState'

export default function MaintenanceForm() {
  return (
    <NgForm
      formType="maintenance"
      formInitialState={formInitialState}
      validationSchema={validationSchema}
      localStorageKey={LOCAL_STORAGE_KEY}
    />
  )
}
