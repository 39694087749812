import * as yup from 'yup'

const purchaseValidationSchema = yup.object().shape({
  billingFirstName: yup.string()
    .min(2, 'El nombre debe contener minimo 2 letras')
    .max(26, 'El nombre no debe superar las 26 letras')
    .required('Debes llenar este campo'),
  billingMiddleName: yup.string()
    .max(26, 'El nombre no debe superar las 26 letras'),
  billingLastName: yup.string()
    .min(2, 'El nombre debe contener minimo 2 letras')
    .max(26, 'El nombre no debe superar las 26 letras')
    .required('Debes poner un apellido'),
  idType: yup.string().required('Debes llenar este campo'),
  idNumber: yup.string().required('Debes llenar este campo'),

  // billingPhone: yup.number().typeError('El celular debe tener 10 números y empezar por 3')
  //   .min(3000000000, 'El celular debe tener 10 números y empezar por 3')
  //   .max(3999999999, 'El celular debe tener 10 números y empezar por 3')
  //   .required('Debes llenar este campo'),

  billingPhone: yup.number().typeError('Deben ser números')
    .required('Debes llenar este campo'),

  email: yup.string().email('email inválido')
    .required('Debes llenar este campo'),
  emailOption: yup.boolean(),
  instagramUser: yup.string(),
  billingDepartment: yup.object().required('Debes llenar este campo'),
  billingCity: yup.string().required('Debes llenar este campo'),
  billingAddress: yup.string().required('Debes llenar este campo'),
  billingAdditionalInfo: yup.string(),
  diffShippingInfo: yup.boolean(),
  shippingFirstName: yup.string().when("diffShippingInfo", {
    is: true,
    then: yup.string()
      .min(2, 'El nombre debe contener minimo 2 letras')
      .max(26, 'El nombre no debe superar las 26 letras')
      .required('Debes llenar este campo')
  }),
  shippingMiddleName: yup.string()
    .max(26, 'El nombre no debe superar las 26 letras'),
  shippingLastName: yup.string().when("diffShippingInfo", {
    is: true,
    then: yup.string()
      .max(26, 'El nombre no debe superar las 26 letras')
      .required('Debes poner al menos un apellido')
  }),
  shippingPhone: yup.number().when("diffShippingInfo", {
    is: true,

    // then: yup.number().typeError('El celular debe tener 10 números y empezar por 3')
    //   .min(3000000000, 'El celular debe tener 10 números y empezar por 3')
    //   .max(3999999999, 'El celular debe tener 10 números y empezar por 3')
    //   .required('Debes llenar este campo')

    then: yup.number().typeError('Deben ser números')
      .required('Debes llenar este campo')
  }),
  shippingDepartment: yup.object()
    .when("diffShippingInfo", { is: true, then: yup.object().required('Debes llenar este campo') }),
  shippingCity: yup.string()
    .when("diffShippingInfo", { is: true, then: yup.string().required('Debes llenar este campo') }),
  shippingAddress: yup.string()
    .when("diffShippingInfo", { is: true, then: yup.string().required('Debes llenar este campo') }),
  shippingAdditionalInfo: yup.string(),
  details: yup.string().required('Debes llenar este campo'),
  sendRingSizer: yup.boolean(),
  contactChannel: yup.string(),
  imageFiles: yup.number().typeError('Deben ser números')
    .min(1, 'Debes adjuntar recibo de pago')
    .max(3, 'Puedes adjuntar máximo 3 imágenes')
    .required('Debes adjuntar recibo de pago'),
  termsAndConditions: yup.boolean().oneOf([true], 'Debes aceptar las políticas para realizar la compra'),
})

export default purchaseValidationSchema