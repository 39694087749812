import React from 'react'
import contactChannels from "../constants/contactChannels"
import {
  Form,
  Row,
  Col
} from 'react-bootstrap'
import InputField from '../fields/InputField'
import SelectField from "../fields/SelectField"

export default function SocialFormGroup({ section, ...props}) {
  const marginFix = section === 'quote' || section === 'return' || section === 'ringSize'

  return (
    <Form.Group className={marginFix ? 'mb-0' : ''}>
      <Row>
        <Col className="mb-3 mb-md-0" md>
          <Form.Label>Usuario de Instagram</Form.Label>
          <InputField name="instagramUser" label="Usuario de Instagram" {...props} />
        </Col>

        <Col md>
          <Form.Label>Por que medio te contactaste?</Form.Label>
          <SelectField
            name="contactChannel"
            label="Canal de Contacto"
            options={contactChannels}
            {...props}
          />
        </Col>
      </Row>
    </Form.Group>
  )
}
