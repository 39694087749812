import * as yup from 'yup'

const maintenanceValidationSchema = yup.object().shape({
  maintenanceFirstName: yup.string()
    .min(2, 'El nombre debe contener minimo 2 letras')
    .max(26, 'El nombre no debe superar las 26 letras')
    .required('Debes llenar este campo'),
  maintenanceMiddleName: yup.string()
    .max(26, 'El nombre no debe superar las 26 letras'),
  maintenanceLastName: yup.string()
    .min(2, 'El nombre debe contener minimo 2 letras')
    .max(26, 'El nombre no debe superar las 26 letras')
    .required('Debes poner un apellido'),
  idType: yup.string().required('Debes llenar este campo'),
  idNumber: yup.string().required('Debes llenar este campo'),

  // maintenancePhone: yup.number().typeError('El celular debe tener 10 números y empezar por 3')
  //   .min(3000000000, 'El celular debe tener 10 números y empezar por 3')
  //   .max(3999999999, 'El celular debe tener 10 números y empezar por 3')
  //   .required('Debes llenar este campo'),

  maintenancePhone: yup.number().typeError('Deben ser números')
    .required('Debes llenar este campo'),

  email: yup.string().email('email inválido')
    .required('Debes llenar este campo'),
  emailOption: yup.boolean(),
  instagramUser: yup.string(),
  maintenanceDepartment: yup.object().required('Debes llenar este campo'),
  maintenanceCity: yup.string().required('Debes llenar este campo'),
  maintenanceAddress: yup.string().required('Debes llenar este campo'),
  maintenanceAdditionalInfo: yup.string(),
  receiptId: yup.string().required('Debes llenar este campo'),
  details: yup.string().required('Debes llenar este campo'),
  contactChannel: yup.string(),
  imageFiles: yup.number().typeError('Deben ser números')
  .min(1, 'Debes adjuntar foto del estado actual de tu pieza')
  .max(3, 'Puedes adjuntar máximo 3 imágenes')
  .required('Debes foto del estado actual de tu pieza'),
  termsAndConditions: yup.boolean().oneOf([true], 'Debes aceptar las políticas para realizar la compra.'),
})

export default maintenanceValidationSchema