import React, { useEffect, useCallback, useRef } from 'react'
import {
  Form,
  Alert,
  Row,
  Col,
  Image,
  CloseButton
} from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { useField, ErrorMessage } from 'formik'
import { isMobile } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'

import filter from 'lodash/filter'
import some from 'lodash/some'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import copy from "../constants/copy"

const maxLength = 100
const maxSize = 3145728
const maxFiles = 3

const formatBytes = (bytes, decimals = 2) => {
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  if (bytes === 0) return '0 Bytes'
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

function MultipleFileFormGroup({
  formType,
  imageFiles,
  setImageFiles,
  formik
}) {
  const isPurchase = formType === 'purchase'
  const [field, meta] = useField('imageFiles')
  const prevFieldValue = useRef(imageFiles.length)

  useEffect(() => {
    if (!isEqual(imageFiles.length, prevFieldValue.current)) {
      formik.setFieldValue(field.name, imageFiles.length)
      prevFieldValue.current = imageFiles.length
    }
  }, [formik, field, imageFiles])

  const handleImgDelete = useCallback((receipt) => {
    const newReceips = filter(imageFiles, function (r) { return r !== receipt })
    formik.setFieldValue(field.name, newReceips.length)
    setImageFiles(newReceips)
    // Make sure to revoke the data uris to avoid memory leaks
    URL.revokeObjectURL(receipt.preview)
  }, [field.name, imageFiles, setImageFiles, formik])

  const {
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: 'image/*',
    maxFiles: maxFiles,
    validator: (file) => {
      const errors = []

      if (file.size > maxSize) {
        errors.push({
          code: 'file-too-large',
          message: `Esta imagen no puede exceder ${formatBytes(maxSize)}`
        })
      }

      if (some(imageFiles, ['path', file.path])) {
        errors.push({
          code: 'duplicated-name',
          message: `Ya existe un archivo con el nombre ${file.name}`
        })
      }

      if (file.name.length > maxLength) {
        errors.push({
          code: 'name-too-large',
          message: 'El nombre de esta imagen es muy largo'
        })
      }

      return isEmpty(errors) ? null : errors
    },
    onDrop: useCallback((acceptedFiles, fileRejections) => {
      const mappedAcceptedFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))

      fileRejections.map(fileWrapper => {
        return fileWrapper.errors.map(error => {
          if (error.code === 'file-invalid-type') {
            error.message = 'Este archivo no es una imagen'
          } else if (error.code === 'too-many-files') {
            error.message = 'Puedes adjuntar máximo 3 imágenes'
          }
          return error.message
        })
      })

      if (!isEmpty(mappedAcceptedFiles)) {
        setImageFiles((prevState) => [...prevState, ...mappedAcceptedFiles])
      }
    }, [setImageFiles])
  })

  return (
    <Form.Group className="mb-0 pt-4">
      <Form.Label>{copy.DetailsCard.label[formType]}</Form.Label>
      {isPurchase && <div className="small mt-0 mb-3">
        Ten en cuenta que si la imagen adjuntada no corresponde a tu recibo de pago, este formulario será inválido y tu pedido no ingresará al sistema.
      </div>}
      <div
        className={`NgForm-dnd ${meta.touched && (meta.error ? 'is-invalid' : 'is-valid')}`}
        {...getRootProps()}
      >
        <input name={field.name} {...getInputProps()} />
        <div className="pb-2"><FontAwesomeIcon icon={faCloudUploadAlt} size="2x" /></div>
        {!isMobile && <span>Arrastra la imagen de tu {copy.DetailsCard.label[formType]} aqui, o </span>} Haz <span className="text-decoration-underline">click</span> para seleccionar la imagen
        <ErrorMessage component="div" name={field.name} className="Error-message Error-message--noCorrection" />
      </div>

      {fileRejections.map((fileWrapper, idx) => (
        <Alert key={'errorWrapper' + idx} variant="danger" className="font-size-sm mt-3 mb-0">
          <Row>
            <Col md={5}><strong>{fileWrapper.file.name}</strong></Col>
            <Col md={2}>{formatBytes(fileWrapper.file.size)}</Col>
            <Col md>
              {fileWrapper.errors.map((error, idx) => (
                <div key={'error' + idx}>
                  <FontAwesomeIcon icon={faBan} /> {error.message}
                </div>
              ))}
            </Col>
          </Row>
        </Alert>
      ))}

      {imageFiles.map((receipt, idx) => (
        <Alert key={'receipt' + idx} variant="ng-primary" className="font-size-sm">
          <Row>
            <Col className="d-flex align-items-center justify-content-end mb-2 mb-sm-0" md={{ span: 2, order: 'last' }}>
              <CloseButton className="CloseButton" onClick={() => handleImgDelete(receipt)} />
            </Col>
            <Col className="d-flex flex-column justify-content-center" md={5}>
              <strong>{receipt.name}</strong>
            </Col>
            <Col className="d-flex flex-column justify-content-center" md={3}>
              {formatBytes(receipt.size)}
            </Col>
            <Col className="d-flex flex-column justify-content-center" md={2}>
              <Image src={receipt.preview} thumbnail />
            </Col>
          </Row>
        </Alert>
      ))}
    </Form.Group>
  )
}

export default MultipleFileFormGroup
