import * as yup from 'yup'

const quoteValidationSchema = yup.object().shape({
  quoteFirstName: yup.string()
    .min(2, 'El nombre debe contener minimo 2 letras')
    .max(26, 'El nombre no debe superar las 26 letras')
    .required('Debes llenar este campo'),
  quoteMiddleName: yup.string()
    .max(26, 'El nombre no debe superar las 26 letras'),
  quoteLastName: yup.string()
    .min(2, 'El nombre debe contener minimo 2 letras')
    .max(26, 'El nombre no debe superar las 26 letras')
    .required('Debes poner un apellido'),
  idType: yup.string().required('Debes llenar este campo'),
  idNumber: yup.string().required('Debes llenar este campo'),

  // quotePhone: yup.number().typeError('El celular debe tener 10 números y empezar por 3')
  //   .min(3000000000, 'El celular debe tener 10 cifras y empezar por 3')
  //   .max(3999999999, 'El celular debe tener 10 cifras y empezar por 3')
  //   .required('Debes llenar este campo'),

  quotePhone: yup.number().typeError('Deben ser números')
    .required('Debes llenar este campo'),


  email: yup.string().email('email inválido')
    .required('Debes llenar este campo'),
  emailOption: yup.boolean(),
  instagramUser: yup.string(),

  // pieces: yup.array()
  //   .required('Debes especificar pieza'),


  // pieces: yup.array().of(
  //   yup.object().shape({
  //     id: yup.number()
  //       .required("id required"),
  //     type: yup.string()
  //       .required("Type required")
  //   })
  // ),

  // pieces: yup.array().of(
  //   yup.object().test(
  //     'name',
  //     'message',
  //     (el) => {
  //       console.log('*** el:', el)
  //       return true
  //     }
  //   )
  // ),


  details: yup.string().required('Debes llenar este campo'),
  contactChannel: yup.string(),
  imageFiles: yup.number().typeError('Deben ser números')
    .min(1, 'Debes adjuntar un referente')
    .max(3, 'Puedes adjuntar máximo 3 imágenes')
    .required('Debes adjuntar un referente'),
  termsAndConditions: yup.boolean().oneOf([true], 'Debes aceptar las políticas para realizar la compra'),
})

export default quoteValidationSchema
