const purchaseFormInitialState = {
  billingFirstName: '',
  billingMiddleName: '',
  billingLastName: '',
  idType: 'CC',
  idNumber: '',
  billingPhone: '',
  email: '',
  emailOption: true,
  instagramUser: '',
  billingDepartment: '',
  billingCity: '',
  billingAddress: '',
  billingAdditionalInfo: '',
  diffShippingInfo: false,
  shippingFirstName: '',
  shippingMiddleName: '',
  shippingLastName: '',
  shippingPhone: '',
  shippingDepartment: '',
  shippingCity: '',
  shippingAddress: '',
  shippingAdditionalInfo: '',
  details: '',
  sendRingSizer: false,
  contactChannel: 'whatsapp',
  imageFiles: 0,
  termsAndConditions: false,
  createdAt: '',
  isMobile: false,
  counterId: ''
}

export default purchaseFormInitialState