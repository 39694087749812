import React from 'react'
import moment from 'moment'
import 'moment/locale/es'
import {
  Modal,
  Row,
  Col
} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faArrowAltCircleRight
} from '@fortawesome/free-solid-svg-icons'

export default function StatusLogsModal({
  showStatusLogsModal,
  setShowStatusLogsModal,
  listItemData,
  statusLogs
}) {
  const handleClose = () => {
    setShowStatusLogsModal(false)
  }

  return (
    <Modal
      size="xl"
      show={showStatusLogsModal}
      onHide={handleClose}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          Historial de estados de {listItemData.counterId}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {statusLogs.map((statusLog, idx) => {
          const statusLogData = statusLog.data()
          const timeStamp = moment(statusLogData.timeStamp.seconds * 1000)
          const submitDate = timeStamp.format("ddd DD MMM YYYY hh:mm:ss a")
          const fromNow = timeStamp.fromNow()
          const prevStatusLog = statusLogs[idx - 1]

          return (
            <Row key={statusLog.id}>
              <Col md>{prevStatusLog && <>{prevStatusLog.data().status} <FontAwesomeIcon icon={faArrowAltCircleRight} /></>} {statusLogData.status}</Col>
              <Col className="flex-grow-0" md>{statusLogData.user}</Col>
              <Col md>{submitDate + ' (' + fromNow + ')'}</Col>
            </Row>
          )
        })}
      </Modal.Body>
    </Modal>
  )
}
