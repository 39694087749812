// const phalangeTags = [
//   {
//     id: 'A',
//     position: [{top: 120, left: 16}, {top: 66, left: 10}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'B',
//     position: [{top: 155, left: 18}, {top: 87, left: 10}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'C',
//     position: [{top: 206, left: 26}, {top: 117, left: 15}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'D',
//     position: [{top: 70, left: 51}, {top: 36, left: 29}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'E',
//     position: [{top: 109, left: 51}, {top: 59, left: 29}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'F',
//     position: [{top: 184, left: 59}, {top: 102, left: 33}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'G',
//     position: [{top: 38, left: 86}, {top: 22, left: 49}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'H',
//     position: [{top: 83, left: 90}, {top: 45, left: 51}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'I',
//     position: [{top: 162, left: 94}, {top: 91, left: 53}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'J',
//     position: [{top: 54, left: 149}, {top: 30, left: 84}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'K',
//     position: [{top: 91, left: 152}, {top: 53, left: 86}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'L',
//     position: [{top: 160, left: 150}, {top: 91, left: 85}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'M',
//     position: [{top: 203, left: 224}, {top: 110, left: 128}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'N',
//     position: [{top: 250, left: 214}, {top: 143, left: 120}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'O',
//     position: [{top: 120, left: 494}, {top: 66, left: 279}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'P',
//     position: [{top: 155, left: 493}, {top: 87, left: 278}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'Q',
//     position: [{top: 206, left: 485}, {top: 117, left: 273}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'R',
//     position: [{top: 70, left: 461}, {top: 36, left: 260}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'S',
//     position: [{top: 109, left: 460}, {top: 59, left: 259}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'T',
//     position: [{top: 184, left: 453}, {top: 102, left: 255}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'U',
//     position: [{top: 38, left: 425}, {top: 22, left: 240}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'V',
//     position: [{top: 83, left: 422}, {top: 45, left: 238}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'W',
//     position: [{top: 162, left: 417}, {top: 91, left: 235}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'X',
//     position: [{top: 54, left: 362}, {top: 30, left: 204}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'Y',
//     position: [{top: 91, left: 359}, {top: 53, left: 202}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: 'Z',
//     position: [{top: 160, left: 362}, {top: 91, left: 204}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: '&',
//     position: [{top: 203, left: 287}, {top: 110, left: 161}],
//     active: false, stored: false, value: ''
//   },
//   {
//     id: '@',
//     position: [{top: 250, left: 297}, {top: 143, left: 168}],
//     active: false, stored: false, value: ''
//   }
// ]

// const phalangeTags = [
//   {
//     name: 'Mano Izquierda, Dedo Meñique, Falange Distal',
//     position: [{top: 120, left: 16}, {top: 66, left: 10}]
//   },
//   {
//     name: 'Mano Izquierda, Dedo Meñique, Falange Media',
//     position: [{top: 155, left: 18}, {top: 87, left: 10}]
//   },
//   {
//     name: 'Mano Izquierda, Dedo Meñique, Falange Proximal',
//     position: [{top: 206, left: 26}, {top: 117, left: 15}]
//   },
//   {
//     name: 'Mano Izquierda, Dedo Anular, Falange Distal',
//     position: [{top: 70, left: 51}, {top: 36, left: 29}]
//   },
//   {
//     name: 'Mano Izquierda, Dedo Anular, Falange Media',
//     position: [{top: 109, left: 51}, {top: 59, left: 29}]
//   },
//   {
//     name: 'Mano Izquierda, Dedo Anular, Falange Proximal',
//     position: [{top: 184, left: 59}, {top: 102, left: 33}]
//   },
//   {
//     name: 'Mano Izquierda, Dedo Medio, Falange Distal',
//     position: [{top: 38, left: 86}, {top: 22, left: 49}]
//   },
//   {
//     name: 'Mano Izquierda, Dedo Medio, Falange Media',
//     position: [{top: 83, left: 90}, {top: 45, left: 51}]
//   },
//   {
//     name: 'Mano Izquierda, Dedo Medio, Falange Proximal',
//     position: [{top: 162, left: 94}, {top: 91, left: 53}]
//   },
//   {
//     name: 'Mano Izquierda, Dedo Índice, Falange Distal',
//     position: [{top: 54, left: 149}, {top: 30, left: 84}]
//   },
//   {
//     name: 'Mano Izquierda, Dedo Índice, Falange Media',
//     position: [{top: 91, left: 152}, {top: 53, left: 86}]
//   },
//   {
//     name: 'Mano Izquierda, Dedo Índice, Falange Proximal',
//     position: [{top: 160, left: 150}, {top: 91, left: 85}]
//   },
//   {
//     name: 'Mano Izquierda, Dedo Pulgar, Falange Distal',
//     position: [{top: 203, left: 224}, {top: 110, left: 128}]
//   },
//   {
//     name: 'Mano Izquierda, Dedo Pulgar, Falange Proximal',
//     position: [{top: 250, left: 214}, {top: 143, left: 120}]
//   },
//   {
//     name: 'Mano Derecha, Dedo Meñique, Falange Distal',
//     position: [{top: 120, left: 494}, {top: 66, left: 279}]
//   },
//   {
//     name: 'Mano Derecha, Dedo Meñique, Falange Media',
//     position: [{top: 155, left: 493}, {top: 87, left: 278}]
//   },
//   {
//     name: 'Mano Derecha, Dedo Meñique, Falange Proximal',
//     position: [{top: 206, left: 485}, {top: 117, left: 273}]
//   },
//   {
//     name: 'Mano Derecha, Dedo Anular, Falange Distal',
//     position: [{top: 70, left: 461}, {top: 36, left: 260}]
//   },
//   {
//     name: 'Mano Derecha, Dedo Anular, Falange Media',
//     position: [{top: 109, left: 460}, {top: 59, left: 259}]
//   },
//   {
//     name: 'Mano Derecha, Dedo Anular, Falange Proximal',
//     position: [{top: 184, left: 453}, {top: 102, left: 255}]
//   },
//   {
//     name: 'Mano Derecha, Dedo Medio, Falange Distal',
//     position: [{top: 38, left: 425}, {top: 22, left: 240}]
//   },
//   {
//     name: 'Mano Derecha, Dedo Medio, Falange Media',
//     position: [{top: 83, left: 422}, {top: 45, left: 238}]
//   },
//   {
//     name: 'Mano Derecha, Dedo Medio, Falange Proximal',
//     position: [{top: 162, left: 417}, {top: 91, left: 235}]
//   },
//   {
//     name: 'Mano Derecha, Dedo Índice, Falange Distal',
//     position: [{top: 54, left: 362}, {top: 30, left: 204}]
//   },
//   {
//     name: 'Mano Derecha, Dedo Índice, Falange Media',
//     position: [{top: 91, left: 359}, {top: 53, left: 202}]
//   },
//   {
//     name: 'Mano Derecha, Dedo Índice, Falange Proximal',
//     position: [{top: 160, left: 362}, {top: 91, left: 204}]
//   },
//   {
//     name: 'Mano Derecha, Dedo Pulgar, Falange Distal',
//     position: [{top: 203, left: 287}, {top: 110, left: 161}]
//   },
//   {
//     name: 'Mano Derecha, Dedo Pulgar, Falange Proximal',
//     position: [{top: 250, left: 297}, {top: 143, left: 168}]
//   }
// ]

const phalangeTags = [
  {
    name: 'Mano Izquierda, Dedo Meñique, Falange Distal',
    position: [{top: 120, left: 16}, {top: 80, left: 9}]
  },
  {
    name: 'Mano Izquierda, Dedo Meñique, Falange Media',
    position: [{top: 155, left: 18}, {top: 109, left: 13}]
  },
  {
    name: 'Mano Izquierda, Dedo Meñique, Falange Proximal',
    position: [{top: 206, left: 26}, {top: 138, left: 16}]
  },
  {
    name: 'Mano Izquierda, Dedo Anular, Falange Distal',
    position: [{top: 70, left: 51}, {top: 44, left: 33}]
  },
  {
    name: 'Mano Izquierda, Dedo Anular, Falange Media',
    position: [{top: 109, left: 51}, {top: 71, left: 34}]
  },
  {
    name: 'Mano Izquierda, Dedo Anular, Falange Proximal',
    position: [{top: 184, left: 59}, {top: 122, left: 39}]
  },
  {
    name: 'Mano Izquierda, Dedo Medio, Falange Distal',
    position: [{top: 38, left: 86}, {top: 24, left: 56}]
  },
  {
    name: 'Mano Izquierda, Dedo Medio, Falange Media',
    position: [{top: 83, left: 90}, {top: 57, left: 60}]
  },
  {
    name: 'Mano Izquierda, Dedo Medio, Falange Proximal',
    position: [{top: 162, left: 94}, {top: 109, left: 65}]
  },
  {
    name: 'Mano Izquierda, Dedo Índice, Falange Distal',
    position: [{top: 54, left: 149}, {top: 31, left: 98}]
  },
  {
    name: 'Mano Izquierda, Dedo Índice, Falange Media',
    position: [{top: 91, left: 152}, {top: 59, left: 100}]
  },
  {
    name: 'Mano Izquierda, Dedo Índice, Falange Proximal',
    position: [{top: 160, left: 150}, {top: 111, left: 98}]
  },
  {
    name: 'Mano Izquierda, Dedo Pulgar, Falange Distal',
    position: [{top: 203, left: 224}, {top: 134, left: 148}]
  },
  {
    name: 'Mano Izquierda, Dedo Pulgar, Falange Proximal',
    position: [{top: 250, left: 214}, {top: 169, left: 140}]
  },
  {
    name: 'Mano Derecha, Dedo Meñique, Falange Distal',
    position: [{top: 120, left: 494}, {top: 80, left: 326}]
  },
  {
    name: 'Mano Derecha, Dedo Meñique, Falange Media',
    position: [{top: 155, left: 493}, {top: 109, left: 325}]
  },
  {
    name: 'Mano Derecha, Dedo Meñique, Falange Proximal',
    position: [{top: 206, left: 485}, {top: 138, left: 320}]
  },
  {
    name: 'Mano Derecha, Dedo Anular, Falange Distal',
    position: [{top: 70, left: 461}, {top: 44, left: 304}]
  },
  {
    name: 'Mano Derecha, Dedo Anular, Falange Media',
    position: [{top: 109, left: 460}, {top: 71, left: 303}]
  },
  {
    name: 'Mano Derecha, Dedo Anular, Falange Proximal',
    position: [{top: 184, left: 453}, {top: 122, left: 298}]
  },
  {
    name: 'Mano Derecha, Dedo Medio, Falange Distal',
    position: [{top: 38, left: 425}, {top: 24, left: 280}]
  },
  {
    name: 'Mano Derecha, Dedo Medio, Falange Media',
    position: [{top: 83, left: 422}, {top: 57, left: 277}]
  },
  {
    name: 'Mano Derecha, Dedo Medio, Falange Proximal',
    position: [{top: 162, left: 417}, {top: 109, left: 273}]
  },
  {
    name: 'Mano Derecha, Dedo Índice, Falange Distal',
    position: [{top: 54, left: 362}, {top: 31, left: 239}]
  },
  {
    name: 'Mano Derecha, Dedo Índice, Falange Media',
    position: [{top: 91, left: 359}, {top: 59, left: 237}]
  },
  {
    name: 'Mano Derecha, Dedo Índice, Falange Proximal',
    position: [{top: 160, left: 362}, {top: 111, left: 238}]
  },
  {
    name: 'Mano Derecha, Dedo Pulgar, Falange Distal',
    position: [{top: 203, left: 287}, {top: 134, left: 189}]
  },
  {
    name: 'Mano Derecha, Dedo Pulgar, Falange Proximal',
    position: [{top: 250, left: 297}, {top: 169, left: 196}]
  }
]

export default phalangeTags