import * as yup from 'yup'

const returnValidationSchema = yup.object().shape({
  returnFirstName: yup.string()
    .min(2, 'El nombre debe contener minimo 2 letras')
    .max(26, 'El nombre no debe superar las 26 letras')
    .required('Debes llenar este campo'),
  returnMiddleName: yup.string()
    .max(26, 'El nombre no debe superar las 26 letras'),
  returnLastName: yup.string()
    .min(2, 'El nombre debe contener minimo 2 letras')
    .max(26, 'El nombre no debe superar las 26 letras')
    .required('Debes poner un apellido'),

  // returnPhone: yup.number().typeError('El celular debe tener 10 números y empezar por 3')
  //   .min(3000000000, 'El celular debe tener 10 números y empezar por 3')
  //   .max(3999999999, 'El celular debe tener 10 números y empezar por 3')
  //   .required('Debes llenar este campo'),

  returnPhone: yup.number().typeError('Deben ser números')
    .required('Debes llenar este campo'),

  email: yup.string().email('email inválido')
    .required('Debes llenar este campo'),
  emailOption: yup.boolean(),
  idType: yup.string().required('Debes llenar este campo'),
  idNumber: yup.string().required('Debes llenar este campo'),
  bank: yup.string().required('Debes llenar este campo'),
  accountNumber: yup.number().typeError('El número de cuenta solo pueden ser números')
    .required('Debes llenar este campo'),
  accountType: yup.string().required('Debes llenar este campo'),
  details: yup.string().required('Debes llenar este campo'),
  termsAndConditions: yup.boolean().oneOf([true], 'Debes aceptar las políticas para realizar la compra'),
})

export default returnValidationSchema