const exchangeFormInitialState = {
  exchangeFirstName: '',
  exchangeMiddleName: '',
  exchangeLastName: '',
  idType: 'CC',
  idNumber: '',
  exchangePhone: '',
  email: '',
  emailOption: true,
  instagramUser: '',
  exchangeDepartment: '',
  exchangeCity: '',
  exchangeAddress: '',
  exchangeAdditionalInfo: '',
  details: '',
  receiptId: '',
  contactChannel: 'whatsapp',
  imageFiles: 0,
  termsAndConditions: false,
  createdAt: '',
  isMobile: false,
  counterId: ''
}

export default exchangeFormInitialState