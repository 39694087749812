const formSections = {
  billing: [
    'billingFirstName',
    'billingMiddleName',
    'billingLastName',
    'idType',
    'idNumber',
    'billingPhone',
    'email',
    'emailOption',
    'billingDepartment',
    'billingCity',
    'billingAddress',
    'billingAdditionalInfo'
  ],
  shipping: [
    'shippingFirstName',
    'shippingMiddleName',
    'shippingLastName',
    'shippingPhone',
    'shippingDepartment',
    'shippingCity',
    'shippingAddress',
    'shippingAdditionalInfo'
  ],
  purchase: [
    'details',
    'sendRingSizer',
    'imageFiles'
  ],
  terms: [
    'termsAndConditions'
  ]
}

export default formSections