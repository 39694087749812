import React, { useState, useEffect, useCallback } from 'react'
import { database } from '../../firebase'
import { Formik, Form } from 'formik'
import { isMobile } from 'react-device-detect'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3'
import forEach from 'lodash/forEach'
import some from 'lodash/some'
import includes from 'lodash/includes'
import phalangeTags from "../constants/phalangeTags"
import {
  Alert,
  Button,
  Card,
  Row,
  Col
} from 'react-bootstrap'
import ResponsiveBreakPoints from '../utilities/ResponsiveBreakPoints'
import FormContainer from './FormContainer'
import FormHeader from "./FormHeader"
import FormCard from '../cards/FormCard'
import RingSizeFormGroup from "../formGroups/RingSizeFormGroup"
import DetailsCard from "../cards/DetailsCard"
import TermsAndConditionsCard from '../cards/TermsAndConditionsCard'
import ResetModal from '../modals/ResetModal'
import SendModal from '../modals/SendModal'
import ConfirmationModal from '../modals/ConfirmationModal'

const upperFields = ['Name', 'Address', 'AdditionalInfo']

const YourReCaptchaComponent = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    // const token = await executeRecaptcha('yourAction')
    // Do whatever you want with the token
    // console.log('*** token:', token)
  }, [executeRecaptcha])

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  // return <button onClick={handleReCaptchaVerify}>Verify recaptcha</button>
  return <></>
}

const objStructureCompare = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(key => obj2.hasOwnProperty(key))

// const areTagPositionsEq = (stored, latest) => (
//   JSON.stringify(stored.map(tag => tag.position)) === JSON.stringify(latest.map(tag => tag.position))
// )

export default function NgForm({
  formType,
  formInitialState,
  validationSchema,
  localStorageKey
}) {
  const isPurchase = formType === 'purchase'
  const isRingSize = formType === 'ringSize'
  const [formState, setFormState] = useState(formInitialState)
  const [imageFiles, setImageFiles] = useState([])
  const [showResetModal, setShowResetModal] = useState(false)
  const [showSendModal, setShowSendModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  useEffect(() => {
    const storedFormState = JSON.parse(localStorage.getItem(localStorageKey))
    // const fillFromStored = (isRingSize && storedFormState) ? areTagPositionsEq(storedFormState.ringSizeCards[0].phalangeTags, phalangeTags) : true

    // if (storedFormState && objStructureCompare(storedFormState, formInitialState) && fillFromStored) {
    if (storedFormState && objStructureCompare(storedFormState, formInitialState)) {
      // console.log('*** storedFormState:', storedFormState)
      setFormState(storedFormState)

      forEach([
        ...(isPurchase ? ['diffShippingInfo'] : []),
        'termsAndConditions'
      ], function (key) {
        setFormState(prevState => {
          return {...prevState, [key]: false}
        })
      })

      forEach([
        ...(isPurchase ? ['shippingDepartment', 'shippingCity'] : []),
        'counterId'
      ], function (key) {
        setFormState(prevState => ({...prevState, [key]: ''}))
      })

      setFormState(prevState => ({...prevState, imageFiles: 0}))
    }

    setFormState(prevState => ({...prevState, createdAt: database.getCurrentTimestamp(), isMobile: isMobile}))
  }, [formInitialState, localStorageKey, isPurchase])

  useEffect(() => {
    // console.log('*** formState:', formState)
    localStorage.setItem(localStorageKey, JSON.stringify(formState))
  }, [formState, localStorageKey])

  const handleBlur = useCallback((formik) => {
    forEach(formik.values, function (val, key) {
      let fixedVal = val

      if (includes(key, 'Phone')) {
        fixedVal = fixedVal.replace(/\D/g, '');
      }

      if (key === 'idNumber') {
        fixedVal = fixedVal.replace(/[^\w\s]/gi, '')
      }

      if (!formik.values['diffShippingInfo'] && includes(key, 'shipping') && key !== 'diffShippingInfo') {
        fixedVal = ''
      }

      if (typeof fixedVal === 'string') {
        fixedVal = fixedVal.trim()
      }

      if (some(upperFields, (el) => includes(key, el))) {
        fixedVal = fixedVal.toUpperCase()
      }

      formik.values[key] = fixedVal
    })

    setFormState(formik.values)
  }, [])

  const handleReset = useCallback(() => {
    setImageFiles([])
    // setFormState(formInitialState)

    // setFormState(prevState => {
    //   return { ...prevState, createdAt: database.getCurrentTimestamp() }
    // })

    // setFormState(prevState => {
    //   return { ...prevState, isMobile: isMobile }
    // })

    // setFormState(prevState => {
    //   return {
    //     ...prevState,
    //     createdAt: database.getCurrentTimestamp(),
    //     isMobile: isMobile,
    //     ...(isRingSize && {phalangeTags: phalangeTags})
    //   }
    // })

    setFormState({
      ...formInitialState,
      createdAt: database.getCurrentTimestamp(),
      isMobile: isMobile,
      ...(isRingSize && {phalangeTags: phalangeTags})
    })

    setShowResetModal(false)
  }, [formInitialState])

  return (
    <>
      {/* <ResponsiveBreakPoints className="text-right" /> */}
      <FormContainer>
        <Card className="border-0">
          <Card.Body>
            <FormHeader formType={formType} />
            <Formik
              enableReinitialize
              initialValues={formState}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                handleBlur({
                  values: values
                })
                setShowSendModal(true)
              }}
            >
              {formik => (
                <Form className="NgForm" >
                  {[...(isPurchase ? ['billing', 'shipping'] : [formType])].map((section, idx) => (
                    <FormCard
                      key={idx}
                      section={section}
                      formik={formik}
                      handleBlur={handleBlur}
                    />
                  ))}

                  {/* {isRingSize && <RingSizeFormGroup {...props} />} */}
                  {isRingSize && <RingSizeFormGroup
                    formType={formType}
                    imageFiles={imageFiles}
                    setImageFiles={setImageFiles}
                    formState={formState}
                    setFormState={setFormState}
                    formik={formik}
                    handleBlur={handleBlur}
                  />}

                  <DetailsCard
                    formType={formType}
                    imageFiles={imageFiles}
                    setImageFiles={setImageFiles}
                    formState={formState}
                    setFormState={setFormState}
                    formik={formik}
                    handleBlur={handleBlur}
                  />
                  <TermsAndConditionsCard
                    formik={formik}
                    handleBlur={handleBlur}
                  />

                  <Row>
                    <Col className="mt-3" sm>
                      <Button variant="ng-secondary" size="lg" className="w-100" onClick={() => setShowResetModal(true)}>
                        Borrar Formulario
                      </Button>
                    </Col>
                    <Col className="mt-3" sm>
                      <Button variant="ng-primary" size="lg" className="w-100" type="submit">
                        Enviar
                      </Button>
                    </Col>
                  </Row>

                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </FormContainer>

      <ResetModal
        showResetModal={showResetModal}
        setShowResetModal={setShowResetModal}
        handleReset={handleReset}
      />
      <SendModal
        formType={formType}
        showSendModal={showSendModal}
        setShowSendModal={setShowSendModal}
        setShowConfirmationModal={setShowConfirmationModal}
        imageFiles={imageFiles}
        formState={formState}
        setFormState={setFormState}
      />
      <ConfirmationModal
        formType={formType}
        showConfirmationModal={showConfirmationModal}
        formState={formState}
        setFormState={setFormState}
      />

      <GoogleReCaptchaProvider
        reCaptchaKey="6Lf4bIAcAAAAAMnkZk2F65N_9ZGqI-NpU1j0uR0h"
        language="es-419"
      >
        <YourReCaptchaComponent />
      </GoogleReCaptchaProvider>
    </>
  )
}
