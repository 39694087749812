const warrantyFormInitialState = {
  warrantyFirstName: '',
  warrantyMiddleName: '',
  warrantyLastName: '',
  idType: 'CC',
  idNumber: '',
  warrantyPhone: '',
  email: '',
  emailOption: true,
  instagramUser: '',
  warrantyDepartment: '',
  warrantyCity: '',
  warrantyAddress: '',
  warrantyAdditionalInfo: '',
  details: '',
  receiptId: '',
  contactChannel: 'whatsapp',
  imageFiles: 0,
  termsAndConditions: false,
  createdAt: '',
  isMobile: false,
  counterId: ''
}

export default warrantyFormInitialState