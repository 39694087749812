import React from 'react'
import {
  Modal,
  Carousel,
  Image
} from 'react-bootstrap'

export default function RingSizeModal({
  showRingSizeModal,
  setShowRingSizeModal
}) {
  const handleClose = () => setShowRingSizeModal(false)

  return (
    <Modal
      size="lg"
      show={showRingSizeModal}
      onHide={handleClose}
      centered
    >
      <Modal.Body>
        <Carousel variant="dark">
          {[0,1,2,3,4].map((idx) => (
            <Carousel.Item key={`ringSize${idx}`}>
              <Image src={`/assets/ring-size/image${idx}.jpeg`} thumbnail />
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal.Body>
    </Modal>
  )
}
