import React, { useEffect } from 'react'
import copy from "../constants/copy"
import {
  Modal,
  Alert,
  Button
} from 'react-bootstrap'

export default function ConfirmationModal({
  formType,
  showConfirmationModal,
  formState,
  setFormState
}) {
  useEffect(() => {
    if (showConfirmationModal && (formState.details === '')) {
      window.location.href = 'https://ninagatojoyeria.com/'
    }
  }, [showConfirmationModal, formState])

  const routeChange = () =>{
    setFormState(prevState => {
      return { ...prevState, details: '' }
    })
  }

  return (
    <Modal
      size="lg"
      show={showConfirmationModal}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          {copy.ConfirmationModal.confirmationMessage[formType]}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="ng-primary" className="mt-0">
          <Alert.Heading>Te hemos enviado un email a {formState.email}</Alert.Heading>
          <p>
            Allí podras encontrar toda la información de tu {copy.action[formType]} <strong>{formState.counterId}</strong>
          </p>
          <p>
            Ya puedes cerrar esta página y continuar en nuestro sitio para que sigas viendo mas cosas bonitas que tenemos para tí.
          </p>
          <hr />
          <p className="mb-0">
            Si no logras ver el email que te enviamos, por favor revisa tu carpeta de spam.
          </p>
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="ng-primary" onClick={routeChange}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  )
}
