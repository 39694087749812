import React from 'react'
import { Container } from "react-bootstrap"

function FormContainer({ children }) {
  return (
    <Container
      className="d-flex justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: "1140px" }}>
        {children}
      </div>
    </Container>
  )
}

export default FormContainer
