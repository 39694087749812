import React from 'react'
import {
  Form,
  Row,
  Col
} from 'react-bootstrap'
import CheckboxField from '../fields/CheckboxField'
import InputField from '../fields/InputField'

export default function ContactFormGroup({
  section,
  ...props
}) {
  const isShipping = section === 'shipping'

  return (
    <Form.Group>
      <Row>
        <Col className={!isShipping ? 'mb-3 mb-md-0' : ''} md>
          <Form.Label>Celular</Form.Label>
          <InputField name={section + 'Phone'} type="tel" label="Número Celular" {...props} />
        </Col>

        {!isShipping && <>
          <Col md>
            <Form.Label className="d-flex justify-content-between align-items-center">
              <span>Email</span>
              <CheckboxField
                name="emailOption"
                className="v-center-checkbox text-dark font-size-sm"
                label="Recibir información"
                {...props}
              />
            </Form.Label>
            <InputField name="email" type="email" label="Email" {...props} />
          </Col>
        </>}
      </Row>
    </Form.Group>
  )
}
