import React from 'react'

import formInitialState from '../constants/returnFormInitialState'
import validationSchema from '../constants/returnValidationSchema'
import NgForm from './NgForm'

const LOCAL_STORAGE_KEY = 'ngSalesApp.returnFormState'

export default function ReturnForm() {
  return (
    <NgForm
      formType="return"
      formInitialState={formInitialState}
      validationSchema={validationSchema}
      localStorageKey={LOCAL_STORAGE_KEY}
    />
  )
}
