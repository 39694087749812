import React from 'react'
import {
  Document,
  Page,
  View,
  Image,
  Text
} from '@react-pdf/renderer'

const styles = {
  page: {
    padding: 20,
    fontFamily: 'Helvetica',
    fontSize: 10
  },
  row: {
    flexDirection: "row",
  },
  cell: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: "auto",
  },
  brand: {
    paddingRight: 10,
    borderRight: '1px solid black',
    textAlign: 'center'
  },
  fromItem: {
    paddingTop: 1,
    fontSize: 8,
    textAlign: 'right'
  },
  shipTo: {
    paddingTop: 8,
    marginTop: 8,
    borderTop: '1px solid black'
  },
  shipToTitle: {
    paddingBottom: 5,
    fontFamily: 'Helvetica-Bold'
  },
  shipToItem: {
    backgroundColor: 'white',
    alignSelf: 'flex-start',
    paddingRight: 5
  },
  backgroundImage: {
    position: 'absolute',
    display: 'block',
    height: '50px',
    width: '50px',
    right: '0',
    bottom: '0'
  }
}

export default function ShippingLabel({ shipTo }) {

  console.log('*** shipTo:', shipTo)

  return (
    <Document>
      <Page size={[175, 284]} orientation="landscape" style={styles.page}>

        <View style={styles.row}>
          <View style={[styles.cell, styles.brand]}>
            <Image src="/assets/LogoNgsas.png" />
            <Text style={{paddingTop: 4}}>NIT 901374524-0</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.fromItem}>301 244 2096</Text>
            <Text style={styles.fromItem}>CALLE 83 C SUR # 55 -40</Text>
            <Text style={styles.fromItem}>LOCAL 3</Text>
            <Text style={styles.fromItem}>LA ESTRELLA - ANTIOQUIA</Text>
          </View>
        </View>
        <View style={styles.shipTo}>
          <Image src="/assets/cat.png" style={styles.backgroundImage} />
          <Text style={styles.shipToTitle}>Destinatari@</Text>
          {shipTo.map((item, idx) => (
            // <Text key={`item${idx}`} style={styles.shipToItem}>{idx < 2 ? item : 'O O O O O O O O O O O O O O O O O O O O O O O1\nO O O O O O O O O O O O O O O O O O O O O O O2 O O O O O O O O O O O O O O O O O O O O O O O3'}</Text>
            <Text key={`item${idx}`} style={styles.shipToItem}>{item}</Text>
          ))}
        </View>

      </Page>
    </Document>
  )
}
