import React, { useMemo } from 'react'
import { useField, ErrorMessage } from 'formik'
import { Form } from 'react-bootstrap'

function InputField({ name, label, formik, handleBlur, ...props }) {
  const [field, meta] = useField(name)
  const classModifier = useMemo(() => {
    switch (name) {
      case 'billingFirstName':
        return 'Error-message--firstName'
      case 'billingAddress':
      case 'shippingAddress':
        return 'Error-message--noCorrection'
      default:
        return ''
    }
  }, [name])

  return (
    <>
      <Form.Control
        className={meta.touched && (meta.error ? 'is-invalid' : 'is-valid')}
        placeholder={label}
        {...props}
        {...field}
        onBlur={e => {
          formik.handleBlur(e)
          handleBlur(formik)
        }}
      />
      <ErrorMessage
        component="div"
        name={field.name}
        className={`Error-message ${classModifier}`}
      />
    </>
  )
}

export default InputField
