import React from 'react'

import formInitialState from '../constants/warrantyFormInitialState'
import validationSchema from '../constants/warrantyValidationSchema'
import NgForm from './NgForm'

const LOCAL_STORAGE_KEY = 'ngSalesApp.warrantyFormState'

export default function WarrantyForm() {
  return (
    <NgForm
      formType="warranty"
      formInitialState={formInitialState}
      validationSchema={validationSchema}
      localStorageKey={LOCAL_STORAGE_KEY}
    />
  )
}
