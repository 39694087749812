import React, { useState } from 'react'
import {
  InputGroup,
  FormControl,
  Button
} from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCopy,
  faCheck
} from '@fortawesome/free-solid-svg-icons'

function CopyField({ label, value }) {
  const [copied, setCopied] = useState(false)

  return (
    <InputGroup className="CopyField">
      <InputGroup.Text className="CopyField-label">{label}</InputGroup.Text>
      <FormControl
        className="CopyField-text"
        size="sm"
        placeholder={value}
        aria-label={label}
        aria-describedby={label}
        readOnly
        disabled
      />

      <CopyToClipboard
        text={value}
        onCopy={() => {
          setCopied(true)
          setTimeout(() => setCopied(false), 3000)
        }}>
        <Button size="sm" variant="ng-primary" className="CopyField-button">
          <FontAwesomeIcon icon={copied ? faCheck : faCopy} className="CopyField-icon" />
           Copiar
        </Button>
      </CopyToClipboard>
    </InputGroup>
  )
}

export default CopyField
