import React, { useState, useEffect } from 'react'
// import phalangeTags from "../constants/phalangeTags"
import formInitialState from '../constants/ringSizeFormInitialState'
import validationSchema from '../constants/ringSizeValidationSchema'
import NgForm from './NgForm'

const LOCAL_STORAGE_KEY = 'ngSalesApp.ringSizeFormState'

export default function RingSizeForm() {
  // const [ringSizeFormInitialState, setRingSizeFormInitialState] = useState(formInitialState)

  // useEffect(() => {
  //   const phalangeTagsWithStates = phalangeTags.map((ptag, idx) => {
  //     console.log('*** idx:', idx)
  //     return {
  //       ...ptag,
  //       active: false,
  //       stored: false,
  //       value: ''
  //     }
  //   })

  //   console.log('*** phalangeTagsWithStates:', phalangeTagsWithStates)

  //   setRingSizeFormInitialState(prevState => (
  //     { ...prevState, phalangeTags: phalangeTagsWithStates }
  //   ))
  // }, [setRingSizeFormInitialState])

  return (
    <NgForm
      formType="ringSize"
      formInitialState={formInitialState}
      validationSchema={validationSchema}
      localStorageKey={LOCAL_STORAGE_KEY}
    />
  )
}
