import React from 'react'
import { Card } from 'react-bootstrap'
import CheckboxField from '../fields/CheckboxField'

function TermsAndConditionsCard(props) {
  return (
    <Card bg="light" text="dark" className="mt-3">
      <Card.Header as="h5">
        Terminos y Condiciones
      </Card.Header>
      <Card.Body>
        <Card.Text className="font-size-sm">
          Declaro que autorizo a Niña Gato s.a.s. para la recolección y tratamiento de mis datos personales, conforme a las <a href="https://ninagatojoyeria.com/privacy-policy-2/" target="_blank" rel="noreferrer">políticas</a> de datos personales.
          <br />
          El comprador es la persona que se registra como usuario en nuestra página web, aplicación o cualquier otro medio de identificación. En caso tal que el comprador utilice medios de pago de otras personas, para adquirir nuestros productos, se entiende que esta operación es entre el comprador y el tercero, y que ese tercero aceptó los términos y condiciones de pagos, tratamiento de datos y consciente esa compra, y que el contratante con Niña Gato S.A.S. es el usuario registrado. Esto aplica también para cualquier otra relación contractual con nuestra compañía.
        </Card.Text>

        {/* <Form.Check type="checkbox" className="custom-checkbox">
          <Form.Check.Input type="checkbox" id="termsAndConditions" checked={formState.termsAndConditions} onChange={handleInputChange} required />
          <Form.Check.Label>
            He leído y acepto las <a href="https://ninagatojoyeria.com/privacy-policy-2/" target="_blank" rel="noreferrer">políticas</a>
          </Form.Check.Label>
          <Form.Control.Feedback type="invalid">Debes aceptar las políticas para realizar la compra.</Form.Control.Feedback>
        </Form.Check> */}

        <CheckboxField
          name="termsAndConditions"
          label={
            <>
              He leído y acepto las <a href="https://ninagatojoyeria.com/privacy-policy-2/" target="_blank" rel="noreferrer">políticas</a>
            </>
          }
          {...props}
        />
      </Card.Body>
    </Card>
  )
}

export default TermsAndConditionsCard