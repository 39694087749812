import React from 'react'
import {
  Modal,
  Button,
} from 'react-bootstrap'

export default function RemoveHandsModal({
  card,
  showRemoveHandsModal,
  setShowRemoveHandsModal,
  handleRemoveCard
}) {
  const handleClose = () => {
    setShowRemoveHandsModal(false)
  }

  return (
    <Modal
      show={showRemoveHandsModal}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          Borrar Medidas
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Estas seguro que deseas borrar {card.name === '' ? 'estas medidas' : `las medidas de ${card.name}`}?
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
        <Button variant="ng-secondary" onClick={handleRemoveCard}>Borrar</Button>
      </Modal.Footer>
    </Modal>
  )
}
