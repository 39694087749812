import React from 'react'
import {
  Form,
  Row,
  Col
} from 'react-bootstrap'
import idTypes from "../constants/idTypes"
import InputField from '../fields/InputField'
import SelectField from '../fields/SelectField'

function IdFormGroup(props) {
  return (
    <Form.Group>
      <Row>
        <Col md className="mb-3 mb-md-0">
          <Form.Label>Tipo de Documento</Form.Label>
          <SelectField
            name="idType"
            label="Documento de Identidad"
            options={idTypes}
            {...props}
          />
        </Col>
        <Col md>
          <Form.Label>Documento de Identidad</Form.Label>
          <InputField
            name="idNumber"
            label="Este también sera tu id de cliente"
            {...props}
          />
        </Col>
      </Row>
    </Form.Group>
  )
}

export default IdFormGroup
