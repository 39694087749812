import * as yup from 'yup'

const ringSizeValidationSchema = yup.object().shape({
  ringSizeFirstName: yup.string()
    .min(2, 'El nombre debe contener minimo 2 letras')
    .max(26, 'El nombre no debe superar las 26 letras')
    .required('Debes llenar este campo'),
  ringSizeMiddleName: yup.string()
    .max(26, 'El nombre no debe superar las 26 letras'),
  ringSizeLastName: yup.string()
  .min(2, 'El nombre debe contener minimo 2 letras')
  .max(26, 'El nombre no debe superar las 26 letras')
  .required('Debes poner un apellido'),

  // ringSizePhone: yup.number().typeError('El celular debe tener 10 números y empezar por 3')
  //   .min(3000000000, 'El celular debe tener 10 cifras y empezar por 3')
  //   .max(3999999999, 'El celular debe tener 10 cifras y empezar por 3')
  //   .required('Debes llenar este campo'),

  ringSizePhone: yup.number().typeError('Deben ser números')
    .required('Debes llenar este campo'),

  email: yup.string().email('email inválido')
    .required('Debes llenar este campo'),
  emailOption: yup.boolean(),
  idType: yup.string().required('Debes llenar este campo'),
  idNumber: yup.string().required('Debes llenar este campo'),
  instagramUser: yup.string(),
  contactChannel: yup.string(),
  details: yup.string(),
  imageFiles: yup.number().typeError('Deben ser números')
    .min(1, 'Debes adjuntar la foto de tu medida')
    .max(3, 'Puedes adjuntar máximo 3 imágenes')
    .required('Debes adjuntar la foto de tu medida'),
  termsAndConditions: yup.boolean().oneOf([true], 'Debes aceptar las políticas para realizar la compra'),
})

export default ringSizeValidationSchema
