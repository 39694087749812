import React, { useState, useEffect, useRef } from 'react'
import copy from "../constants/copy"
import { database, storage } from '../../firebase'
import emailjs from 'emailjs-com'
import Pluralize from 'pluralize'
import forEach from 'lodash/forEach'
import replace from 'lodash/replace'
import join from 'lodash/join'
import phalangeTags from "../constants/phalangeTags"
import {
  Modal,
  Row,
  Col,
  Card,
  Alert,
  Button,
  Spinner
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare, faBan } from '@fortawesome/free-solid-svg-icons'
import InfoCard from '../cards/InfoCard'
import RingSizeFormGroup from "../formGroups/RingSizeFormGroup"

function SendModal({
  formType,
  showSendModal,
  setShowSendModal,
  setShowConfirmationModal,
  imageFiles,
  formState,
  setFormState
}) {
  const collectionType = Pluralize(formType, 2)
  const isPurchase = formType === 'purchase'
  const isRingSize = formType === 'ringSize'
  const section = isPurchase ? 'billing' : formType
  const prevCounterId = useRef(formState.counterId)
  const [disabled, setDisabled] = useState(false)
  // const [true, setVerified] = useState(true)

  const handleClose = () => {
    // setDisabled(true)
    // setVerified(false)
    setShowSendModal(false)
  }

  async function handleSubmit() {
    const counterRef = database.counters.doc(collectionType)
    setDisabled(true)

    counterRef.get().then((counterDoc) => {
      if (counterDoc.exists) {
        const counterDocData = counterDoc.data()
        const newCounterNum = formState.idNumber === '16075473' ? 0 : counterDocData.id + 1

        counterRef.update({
          'id': formState.idNumber === '16075473' ? counterDocData.id : newCounterNum
        }).then(() => {
          const counterLetter = collectionType === 'ringSizes' ? 'M' : formType[0].toUpperCase()
          console.log(`${collectionType} counter successfully updated!`)

          setFormState(prevState => {
            return {...prevState, counterId: counterLetter + newCounterNum}
          })
        }).catch((error) => {
          console.error(`Error updating ${collectionType} counter: `, error)
        })
      } else {
        console.log(`The counter ${collectionType} doesn't exists`)
      }
    }).catch((error) => {
      console.log("Error getting document:", error)
      setDisabled(false)
    })
  }

  useEffect(() => {
    if (formState.counterId !== prevCounterId.current) {
      const customerRef = database.customers.doc(formState.idNumber)
      setDisabled(true)

      if (true) {
        customerRef.get().then((customer) => {
          if (customer.exists) {
            storeInDB(customerRef)
          } else {
            console.log("No such document!")
            customerRef.set({
              createdAt: database.getCurrentTimestamp()
            }).then(() => {
              console.log("customer successfully written!")
              storeInDB(customerRef)
            }).catch((error) => {
              console.error("Error writing customer: ", error)
            })
          }
        }).catch((error) => {
          console.log("Error getting document:", error)
          setDisabled(false)
        })
      }
    }
  }, [formState.counterId, formState.idNumber])

  function storeInDB(customerRef) {
    customerRef.collection(collectionType).add(formState).then((ref) => {
      console.log('Form successfully written!', ref.id, imageFiles)

      const statusLog = {
        status: 'pending',
        timeStamp: database.getCurrentTimestamp(),
        user: formState.email
      }

      customerRef.collection(collectionType).doc(ref.id).collection('statusLogs').add(statusLog).then((statusLogRef) => {
        console.log('statusLogs successfully written!', statusLogRef)
      }).catch((error) => {
        console.error("Error writing statusLogs: ", error)
      })

      if (imageFiles.length > 0) {
        forEach(imageFiles, (image) => {
          const uploadTask = storage.ref(`/${collectionType}/${formState.idNumber}/${ref.id}/${image.name}`).put(image)

          uploadTask.on('state_changed', snapshot => { },
            () => { },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then(url => {
                customerRef.collection(collectionType).doc(ref.id).update({
                  'imageFiles': database.arrayUnion(url)
                }).then(() => {
                  console.log('imageFile successfully updated!')
                  sendEmail()
                }).catch((error) => {
                  console.error('Error updating imageFile: ', error)
                })

              })
            }
          )
        })
      } else {
        sendEmail()
      }
    }).catch((error) => {
      console.error("Error writing Form or updating imageFile: ", error)
    })
  }

  function getFullName(fixSection) {
    return replace(join([formState[fixSection + 'FirstName'], formState[fixSection + 'MiddleName'], formState[fixSection + 'LastName']], ' '), '  ', ' ')
  }

  function getFullAddress(fixSection) {
    const cityDepartment = formState[fixSection + 'City'] + ' - ' + formState[fixSection + 'Department'].department
    return replace(join([formState[fixSection + 'Address'], formState[fixSection + 'AdditionalInfo'], cityDepartment], ', '), ', , ', ', ')
  }

  function sendEmail() {
    const emailTemplate = isPurchase ? (formState.diffShippingInfo ? 'template_jbv48gb' : 'template_vxr57dg') : 'template_fbdlmtr'
    const emailObj = {
      firstName: formState[section + 'FirstName'],
      counterId: formState.counterId,
      details: formState.details,
      fullName: getFullName(section),
      idType: formState.idType,
      idNumber: formState.idNumber,
      phone: formState[section + 'Phone'],
      email: formState.email,
      ...(isPurchase && {
        fullAddress: getFullAddress(section),
        shippingFullName: getFullName('shipping'),
        shippingPhone: formState.shippingPhone,
        shippingFullAddress: getFullAddress('shipping')
      })
    }

    if (isRingSize) {
      let ringSizes = formState.ringSizeCards.filter(card => card.phalangeTags.length > 0).map((card, idx) => {
        let label = card.name !== '' ? card.name : ('Persona ' + (idx + 1))

        return label += '\n' + card.phalangeTags.map(tag => (
          phalangeTags[tag.id].name + ': ' + tag.value
        )).join('\n')
      }).join('\n\n')

      if (ringSizes.length > 0) {
        emailObj.details += ('\n\n' + ringSizes)
      }
    }

    emailjs.send('service_9r8wo0i', emailTemplate, emailObj, 'user_3YlQ6cQAX6EcBOSb8yrju').then((result) => {
      console.log(result.text)
      handleClose()
      setShowConfirmationModal(true)
    }, (error) => {
      console.log(error.text)
    })
  }

  // function onChange(value) {
  //   setVerified(!!value)
  // }

  return (
    <Modal
      size="lg"
      show={showSendModal}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          Verifica tu Información
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        {/* <pre>{JSON.stringify(imageFiles, null, 2)}</pre> */}
        <Row>
          <Col className="pt-3" lg={(isRingSize && formState.ringSizeCards) ? (formState.ringSizeCards.length > 1 ? 12 : 6) : formState.diffShippingInfo ? 6 : 12}>
            <InfoCard type={formType} section={section} formState={formState} />
          </Col>

          {isPurchase && formState.diffShippingInfo && <Col className="mt-3 mt-lg-0" lg={6}>
            <InfoCard type={formType} section="shipping" formState={formState} />
          </Col>}

          {/* {isRingSize && <Col className="mt-3">
            <Card bg="light" text="dark" className="h-100">
              <Card.Header as="h6" className="InfoCard-header">
                <span>Medidas</span>
              </Card.Header>

              <Card.Body className="d-flex flex-column font-size-09">
                <RingSizeFormGroup formState={formState} setFormState={setFormState} isModal />
              </Card.Body>
            </Card>
          </Col>} */}

          {isRingSize && <RingSizeFormGroup formState={formState} setFormState={setFormState} isModal />}

        </Row>
        {formState.details !== '' && <Alert variant="ng-primary" className="my-3">
          <strong>{copy.action[formType]}: </strong>{formState.details}
        </Alert>}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" disabled={disabled} onClick={handleClose}>Editar Formulario</Button>
        <Button variant="ng-primary" disabled={disabled || !true} onClick={handleSubmit}>
          <div className="Button-iconContainer">
            {true ? (disabled ? <Spinner animation="border" size="sm" variant="light" /> : <FontAwesomeIcon icon={faShare} />) : <FontAwesomeIcon icon={faBan} />}
          </div>
          {disabled ? 'Enviando' : 'Enviar'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SendModal
