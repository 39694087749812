import React from 'react'
import { Row, Col } from 'react-bootstrap'

function InfoCardItem({ label, value }) {
  return (
    <Row noGutters>
      <Col className="font-weight-bold col-w-80p">{label}:</Col>
      <Col className="InfoCardItem-value">{value}</Col>
    </Row>
  )
}

export default InfoCardItem
