import React from 'react'
import banksList from "../constants/banksList"
import accountTypes from "../constants/accountTypes"
import {
  Form,
  Row,
  Col
} from 'react-bootstrap'
import SelectField from "../fields/SelectField"
import InputField from '../fields/InputField'

export default function TransferFormGroup(props) {

  return (
    <Form.Group>
      <Row>
        <Col className="mb-3 mb-md-0" md>
          <Form.Label>Banco</Form.Label>
          <SelectField
            name="bank"
            label="Bank"
            options={banksList}
            {...props}
          />
        </Col>
        <Col className="mb-3 mb-md-0" md>
          <Form.Label>Número de Cuenta</Form.Label>
          <InputField name="accountNumber" label="Número de Cuenta" {...props} />
        </Col>
        <Col md>
          <Form.Label>Tipo de Cuenta</Form.Label>
          <SelectField
            name="accountType"
            label="Tipo de Cuenta"
            options={accountTypes}
            {...props}
          />
        </Col>
      </Row>
    </Form.Group>
  )
}
