import React from 'react'
import {
  Form,
  Row,
  Col
} from 'react-bootstrap'
import InputField from '../fields/InputField'

function NameFormGroup({ section, formik, handleBlur }) {
  return (
    <Form.Group>
      <Form.Label>Nombre Completo</Form.Label>
      <Row>
        <Col md className="mb-2 mb-md-0">
          <InputField name={section + 'FirstName'} label="Primer Nombre" formik={formik} handleBlur={handleBlur} />
        </Col>
        <Col md className="mb-2 mb-md-0">
          <InputField name={section + 'MiddleName'} label="Segundo Nombre (opcional)" formik={formik} handleBlur={handleBlur} />
        </Col>
        <Col md>
          <InputField name={section + 'LastName'} label="Apellidos" formik={formik} handleBlur={handleBlur} />
        </Col>
      </Row>
    </Form.Group>
  )
}

export default NameFormGroup
