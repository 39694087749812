import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
  Form,
  Row,
  Col
} from 'react-bootstrap'
import isEmpty from 'lodash/isEmpty'
import departments from '../constants/departments'
import TypeaheadField from '../fields/TypeaheadField'

export default function DepartmentCity({ section, formik }) {
  const [cities, setCities] = useState([])
  const isInit = useRef(true)

  useEffect(() => {
    if (isInit.current && isEmpty(cities) && formik.values[section + 'Department'] !== '') {
      setCities(formik.values[section + 'Department'].cities)
      isInit.current = false
    }
  }, [section, formik, cities])

  const handleDepartmentChange = useCallback((departmentArr) => {
    const departmentObj = departmentArr[0]
    if (formik.values[section + 'Department'] !== '') {
      formik.setFieldValue([section + 'City'], '')
    }

    if (departmentObj) {
      setCities(departmentObj.cities)
      formik.setFieldValue([section + 'Department'], departmentObj)
    } else {
      setCities([])
      formik.setFieldValue([section + 'Department'], '')
    }
  }, [section, formik])

  const handleCityChange = useCallback((city) => {
    if (city[0]) {
      formik.setFieldValue([section + 'City'], city[0])
    }
  }, [section, formik])

  return (
    <Form.Group>
      <Row>
        <Col className="mb-3 mb-md-0" md>
          <Form.Label>Departamento</Form.Label>
          <TypeaheadField
            name={section + "Department"}
            placeholder="Departamento"
            value={formik.values[section + 'Department'].department}
            options={departments}
            labelKey="department"
            formik={formik}
            onChange={handleDepartmentChange}
          />
        </Col>
        <Col md>
          <Form.Label>Ciudad</Form.Label>
          <TypeaheadField
            name={section + "City"}
            placeholder="Ciudad"
            value={formik.values[section + 'City']}
            options={cities}
            formik={formik}
            onChange={handleCityChange}
          />
        </Col>
      </Row>
    </Form.Group>
  )
}
