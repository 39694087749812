import React from 'react'
import {
  Modal,
  Carousel,
  Image
} from 'react-bootstrap'

export default function ImageModal({
  showImageModal,
  setShowImageModal,
  imageFiles
}) {
  const handleClose = () => setShowImageModal(false)

  return (
    <Modal
      size="md"
      show={showImageModal}
      onHide={handleClose}
      centered
    >
      <Modal.Body>
        <Carousel variant="dark" interval={null}>
          {(typeof imageFiles !== 'number') && imageFiles.map((receipt, idx) => (
            <Carousel.Item key={receipt + idx}>
              <Image src={receipt} thumbnail />
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal.Body>
    </Modal>
  )
}
