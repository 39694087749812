import React, { useRef } from 'react'
import { Form } from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faRedoAlt
} from '@fortawesome/free-solid-svg-icons'

export default function RingSizeSelectField({
  tag,
  handleTagClick,
  handleSelectChange,
  handleSelectBlur,
  handleSelectReset,
  isSm,
  isModal
}) {
  const ref = useRef(null);

  return (
    <div className="RingSizeCard-selectContainer">
      <div className={`RingSizeCard-selectLabel ${tag ? 'RingSizeCard-selectLabel--active' : ''}`}>
        {tag ? tag.name : 'Haz Click en alguna de las falanges y selecciona tu talla'}
      </div>
      <Form.Control
        ref={ref}
        as="select"
        size="sm"
        value={tag ? tag.value : ''}
        onFocus={() => {handleTagClick(tag)}}
        onChange={handleSelectChange}
        onBlur={handleSelectBlur}
        disabled={!tag || isModal}
        custom
      >
        {['Talla', '1', '2', '3', '4', '4½', '5', '5½', '6', '6½', '7', '7½', '8', '8½', '9', '9½', '10', '10½', '11', '11½', '12', '13'].map(idx => (
          <option key={`ringSize${idx}`} value={idx}>{idx}</option>
        ))}
      </Form.Control>

      <button
        type="button"
        className="RingSizeCard-selectRemove"
        onClick={() => {handleSelectReset(tag)}}
        onBlur={handleSelectBlur}
        disabled={!tag}
      >
        <FontAwesomeIcon icon={faRedoAlt} />
      </button>
    </div>
  )
}
