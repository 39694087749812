import phalangeTags from "./phalangeTags"

const ringSizeFormInitialState = {
  ringSizeFirstName: '',
  ringSizeMiddleName: '',
  ringSizeLastName: '',
  ringSizePhone: '',
  email: '',
  emailOption: true,
  idType: 'CC',
  idNumber: '',
  instagramUser: '',
  contactChannel: 'whatsapp',
  // phalangeTags: phalangeTags,
  ringSizeCards: [{
    id: 0,
    name: '',
    phalangeTags: [],
    activeHand: 'none'
  }],
  details: '',
  imageFiles: 0,
  termsAndConditions: false,
  createdAt: '',
  isMobile: false,
  counterId: ''
}

export default ringSizeFormInitialState