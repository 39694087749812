import React, { useState, useEffect, useRef } from 'react'
import copy from "../constants/copy"
import formSections from '../constants/formSections'
import {animateScroll as scroll, Element} from 'react-scroll'
import forEach from 'lodash/forEach'
import { Card } from 'react-bootstrap'
import NameFormGroup from '../formGroups/NameFormGroup'
import IdFormGroup from '../formGroups/IdFormGroup'
import ContactFormGroup from '../formGroups/ContactFormGroup'
import SocialFormGroup from '../formGroups/SocialFormGroup'
import DepartmentCity from '../formGroups/DepartmentCity'
import AddressFormGroup from '../formGroups/AddressFormGroup'
import CheckboxField from '../fields/CheckboxField'

export default function FormCard({
  section,
  formik,
  handleBlur
}) {
  const isBilling = section === 'billing'
  const isShipping = section === 'shipping'
  const isQuote = section === 'quote'
  const isReturn = section === 'return'
  const isRingSize = section === 'ringSize'
  const [hasErrors, setErrors] = useState(false)
  const prevSubmitCount = useRef(formik.submitCount)

  useEffect(() => {
    if ((formik.submitCount !== prevSubmitCount.current) && formik.submitCount > 0) {
      prevSubmitCount.current = formik.submitCount
      setErrors(false)

      forEach(formSections[section], function (field) {
        if (field in formik.errors) {
          setErrors(true)
          scroll.scrollToTop()

          // scroller.scrollTo(section, {
          //   duration: 1500,
          //   delay: 100,
          //   smooth: true,
          //   offset: -17
          // })

          return false
        }
      })
    }
  }, [section, formik])

  return (
    <Card bg="light" text="dark" className={isShipping ? 'mt-3' : ''}>
      <Element name={section}></Element>
      <Card.Header as="h5">
        <span>{copy.InfoCard.header[section][+ (formik.values.diffShippingInfo || false)]}</span>
        {hasErrors && <div className="Card-errorMessage">Esta sección contiene errores</div>}
      </Card.Header>
      <Card.Body>
        {isShipping && <CheckboxField
          name="diffShippingInfo"
          className={`text-dark ${formik.values.diffShippingInfo ? 'mb-3' : ''}`}
          label="Selecciona solo si la Dirección de Envío es distinta a la de Facturación"
          formik={formik}
          handleBlur={handleBlur}
        />}
        {(!isShipping || formik.values.diffShippingInfo) && <>
          <NameFormGroup section={section} formik={formik} handleBlur={handleBlur} />
          <ContactFormGroup section={section} formik={formik} handleBlur={handleBlur} />
          {!isShipping && <>
            <IdFormGroup formik={formik} handleBlur={handleBlur} />
            <SocialFormGroup section={section} formik={formik} handleBlur={handleBlur} />
          </>}
          {!(isQuote || isReturn || isRingSize) && <>
            <DepartmentCity section={section} formik={formik} handleBlur={handleBlur} />
            <AddressFormGroup section={section} formik={formik} handleBlur={handleBlur} />
          </>}
        </>}
      </Card.Body>
    </Card>
  )
}
