import React, { useState } from 'react'
import NavBarComponent from './NavBarComponent'
import DashboardList from './DashboardList'

export default function WarrantiesDashboard() {
  const [listState, setListState] = useState([]);
  const [listLimitState, setListLimitState] = useState(50);

  return (
    <>
      <NavBarComponent
        dashboardType="warranties"
        listLimitState={listLimitState}
        listState={listState}
        setListLimitState={setListLimitState}
      />
      <div className="NgDashboard">
        <DashboardList
          dashboardType="warranties"
          listLimitState={listLimitState}
          listState={listState}
          setListState={setListState}
        />
      </div>
    </>
  )
}
