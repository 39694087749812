const pieceTypes = [
  {
    id: 0,
    type: 'select',
    label: 'Tipo de pieza'
  },
  {
    type: 'ring',
    label: 'Anillo',
    material: '',
    ringSize: '',
    hasStone: false,
    stoneType: '',
    pieceDetails: ''
  },
  {
    type: 'earrings',
    label: 'Aretes',
    material: '',
    hasStone: false,
    stoneType: '',
    pieceDetails: ''
  },
  {
    type: 'neckLace',
    label: 'Collar',
    material: '',
    chainLength: '',
    pendant: {
      width: '',
      height: '',
      hasStone: false,
      stoneType: '',
    },
    pieceDetails: ''
  },
  {
    type: 'other',
    label: 'Otro',
    material: '',
    hasStone: false,
    stoneType: '',
    pieceDetails: ''
  }
]

export default pieceTypes