const idTypes = {
  'Cédula de Ciudadanía': 'CC',
  'Cédula de Extranjería Colombiana': 'CEC',
  'Cédula Extranjera': 'CE',
  'Documento Extranjero': 'DE',
  'Pasaporte': 'PA',
  'Registro Civíl': 'RC',
  'Tarjeta de Identidad': 'TI'
}

export default idTypes