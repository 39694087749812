import React, {useState, useEffect, useRef, useCallback} from 'react'
import {
  Image,
  FormControl,
  Button
} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import RingSizeSelectField from "../fields/RingSizeSelectField"
import RemoveHandsModal from '../modals/RemoveHandsModal'

const getSide = id => (id < 14 ? 'left' : 'right')
const hasStoredTags = tags => tags.some(tag => tag.stored)

export default function RingSizeCard({
  isModal,
  card,
  defaultPhalangeTags,
  formState,
  setFormState
}) {
  const wrapperRef = useRef()
  const [storedFlag, setStoredFlag] = useState(true)
  const [phalangeTags, setPhalangeTags] = useState(defaultPhalangeTags)
  const [activePhalange, setActivePhalange] = useState()
  const [activeHand, setActiveHand] = useState('none')
  const [elementDimensions, setElementDimensions] = useState({width: 0, height: 0})
  const [showRemoveHandsModal, setShowRemoveHandsModal] = useState(false)
  const isMd = elementDimensions.width < 800
  const isSm = elementDimensions.width < 725 || isModal

  const handleHandName = useCallback((e) => {
    setFormState(prevState => (
      { ...prevState, ringSizeCards: formState.ringSizeCards.map(ringSizeCard => (
        ringSizeCard.id === card.id ? {...ringSizeCard, name: e.target.value} : ringSizeCard
      ))}
    ))
  }, [formState, setFormState])

  const handleRemoveCard = useCallback(() => {
    setFormState(prevState => ({
      ...prevState,
      ringSizeCards: prevState.ringSizeCards.filter(c => c.id !== card.id)
    }), [card])

    setShowRemoveHandsModal(false)
  }, [setFormState])

  const handleTagClick = useCallback((tag) => {
    setActivePhalange({...tag, active: true})
    setActiveHand(getSide(tag.id))
  }, [formState, setFormState])

  const handleSelectChange = useCallback(e => {
    setActivePhalange(prevState => ({
      ...prevState,
      stored: e.target.value !== 'Talla',
      value: e.target.value !== 'Talla' ? e.target.value : ''
    }))
  }, [formState, setFormState, activePhalange])

  const handleSelectBlur = useCallback(e => {
    if (!e.relatedTarget) {
      setActivePhalange(prevState => ({...prevState, active: false}))
      setActiveHand('none')
    }
  }, [formState, setFormState])

  const handleSelectReset = useCallback((tag) => {
    setActivePhalange(prevState => ({
      ...prevState,
      stored: false,
      value: ''
    }))
  }, [formState, setFormState, activePhalange])

  useEffect(() => {
    function handleResize() {
      setElementDimensions({
        width: wrapperRef.current.offsetWidth,
        height: wrapperRef.current.offsetHeight
      })
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (activePhalange) {
      setPhalangeTags(prevState => prevState.map(ptag => (
        ptag.id === activePhalange.id ? activePhalange : {...ptag, active: false}
      )))

      if (!activePhalange.active) {
        setActivePhalange(null)
      }
    }
  }, [activePhalange])

  useEffect(() => {
    const storedPhalangeTags = phalangeTags.filter(ptag => ptag.stored).map(ptag => ({id: ptag.id, value: ptag.value}))

    setFormState(prevState => (
      { ...prevState, ringSizeCards: prevState.ringSizeCards.map(ringSizeCard => (
        ringSizeCard.id === card.id ? {
          ...ringSizeCard,
          phalangeTags: storedPhalangeTags
        } : ringSizeCard
      ))}
    ))
  }, [phalangeTags])

  useEffect(() => {
    if (storedFlag && card.phalangeTags.length !== 0) {
      setPhalangeTags(prevState => prevState.map(ptag => {
        const storedTag = card.phalangeTags.find(cp => cp.id === ptag.id)
        return storedTag ? {...ptag, stored: true, value: storedTag.value} : ptag
        })
      )

      setStoredFlag(false)
    }
  }, [card, setPhalangeTags])

  return (
    <div className="RingSizeCard">
      <div className="RingSizeCard-content">
        <div className="RingSizeCard-header">
          <FormControl
            className="RingSizeCard-handId"
            name="handid"
            placeholder="De quien son estas patitas?"
            aria-label="Tipo de Piedra"
            size="sm"
            disabled={isModal}
            defaultValue={card.name}
            onBlur={handleHandName}
          />
          {!isModal && <Button
            size="sm"
            variant="ng-secondary"
            className="RingSizeCard-closeBtn"
            onClick={() => (hasStoredTags(phalangeTags) ? setShowRemoveHandsModal(true) : handleRemoveCard())}
            disabled={formState.ringSizeCards.length === 1}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>}
          <RemoveHandsModal
            card={card}
            showRemoveHandsModal={showRemoveHandsModal}
            setShowRemoveHandsModal={setShowRemoveHandsModal}
            handleRemoveCard={handleRemoveCard}
          />
        </div>
        <div
          className={[
            'RingSizeCard-container',
            ...isMd ? ['RingSizeCard-container--isMd'] : [],
            ...isSm ? ['RingSizeCard-container--isSm'] : [],
            ...isModal ? ['RingSizeCard-container--isDisabled'] : []
          ].join(' ')}
          ref={wrapperRef}
        >
          <div className={[
            'RingSizeCard-handWrapper',
            ...(elementDimensions.width < 639 || isModal) ? ['RingSizeCard-handWrapper--isSm'] : []
          ].join(' ')}>
            <div className={[
              'RingSizeCard-handContainer',
              ...(elementDimensions.width < 606 || isModal) ? ['RingSizeCard-handContainer--isSm'] : []
            ].join(' ')}>
              <Image
                className={[
                  'RingSizeCard-hand',
                  ...(['left', 'both'].includes(activeHand) || (isModal && hasStoredTags(phalangeTags.slice(0, phalangeTags.length/2)))) ? ['RingSizeCard-hand--active'] : []
                ].join(' ')}
                src="/assets/ring-size/hand-left.png"
              />
              <Image
                className={[
                  'RingSizeCard-hand',
                  ...(['right', 'both'].includes(activeHand) || (isModal && hasStoredTags(phalangeTags.slice(phalangeTags.length/2))))? ['RingSizeCard-hand--active'] : []
                ].join(' ')}
                src="/assets/ring-size/hand-right.png"
              />
              {phalangeTags.map((tag, idx) => (
                <button
                  type="button"
                  key={`tag${idx}`}
                  className={`RingSizeCard-tagContainer ${(!tag.stored && isModal) ? 'RingSizeCard-tagContainer--disabled' : ''}`}
                  style={tag.position[+ (elementDimensions.width < 606 || !!isModal)]}
                  onClick={() => {handleTagClick(tag)}}
                  onBlur={handleSelectBlur}
                  disabled={isModal}
                >
                  <div className={[
                    'RingSizeCard-tag',
                    ...tag.active ? ['RingSizeCard-tag--active'] : [],
                    ...tag.stored ? ['RingSizeCard-tag--stored'] : [],
                    ...isSm ? ['RingSizeCard-tag--sm'] : [],
                    ...(tag.value.length > 1) ? ['RingSizeCard-tag--smFont'] : []
                  ].join(' ')}>
                    {tag.value.replace(/\s/g, '')}
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>

        {!isModal && <RingSizeSelectField
          tag={activePhalange}
          handleTagClick={handleTagClick}
          handleSelectChange={handleSelectChange}
          handleSelectBlur={handleSelectBlur}
          handleSelectReset={handleSelectReset}
          isSm={isSm}
          isModal={isModal}
        />}

      </div>
    </div>
  )
}
