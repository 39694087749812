const maintenanceFormInitialState = {
  maintenanceFirstName: '',
  maintenanceMiddleName: '',
  maintenanceLastName: '',
  idType: 'CC',
  idNumber: '',
  maintenancePhone: '',
  email: '',
  emailOption: true,
  instagramUser: '',
  maintenanceDepartment: '',
  maintenanceCity: '',
  maintenanceAddress: '',
  maintenanceAdditionalInfo: '',
  details: '',
  receiptId: '',
  contactChannel: 'whatsapp',
  imageFiles: 0,
  termsAndConditions: false,
  createdAt: '',
  isMobile: false,
  counterId: ''
}

export default maintenanceFormInitialState