import React from 'react'

function DropdownButton({ isOpen, onClick }) {
  return (
    <button
      className="toggle-button"
      type="button"
      onClick={onClick}
      onMouseDown={e => {
        // Prevent input from losing focus.
        e.preventDefault()
      }}>
      {isOpen ? '▲' : '▼'}
    </button>
  )
}

export default DropdownButton
