import React from 'react'
import {
  Modal
} from 'react-bootstrap'
import { PDFViewer } from "@react-pdf/renderer"
import ShippingLabel from "../cards/ShippingLabel"

export default function PrintModal({
  showPrintModal,
  setShowPrintModal,
  shipTo
}) {
  const handleClose = () => setShowPrintModal(false)

  return (
    <Modal
      size="md"
      show={showPrintModal}
      onHide={handleClose}
      centered
    >
      <Modal.Body>
        <PDFViewer style={{width: '100%', height: '310px'}}>
          <ShippingLabel shipTo={shipTo} />
        </PDFViewer>
      </Modal.Body>
    </Modal>
  )
}
