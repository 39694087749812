import React from 'react'
import { useField } from 'formik'
import { Form } from 'react-bootstrap'

function SelectField({
  name,
  label,
  options,
  formik,
  handleBlur
}) {
  const [field] = useField(name)

  return (
    <>
      <Form.Control
        as="select"
        aria-label={label}
        {...field}
        onBlur={e => {
          formik.handleBlur(e)
          handleBlur(formik)
        }}
        custom
      >
        {Object.keys(options).map((keyName, idx) => (
          <option key={name + idx} value={options[keyName]}>{keyName}</option>
        ))}
      </Form.Control>
    </>
  )
}

export default SelectField
