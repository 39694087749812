import React from 'react'
import { useField, ErrorMessage } from 'formik'
import { Form } from 'react-bootstrap'

function InputArea({ name, placeholder, formik, handleBlur }) {
  const [field, meta] = useField(name)

  return (
    <>
      <Form.Control
        as="textarea"
        rows={2}
        className={meta.touched && (meta.error ? 'is-invalid' : 'is-valid')}
        placeholder={placeholder}
        {...field}
        onBlur={e => {
          formik.handleBlur(e)
          handleBlur(formik)
        }}
      />
      <ErrorMessage component="div" name={field.name} className="Error-message Error-message--noCorrection" />
    </>
  )
}

export default InputArea
