// import React from 'react'
import React, { useEffect, useRef } from 'react'
import { useField, ErrorMessage } from 'formik'
import { Form } from 'react-bootstrap'
import isEqual from 'lodash/isEqual'
// import forEach from 'lodash/forEach'

function CheckboxField({
  name,
  className,
  label,
  formik,
  handleBlur
}) {
  const [field, meta] = useField({name: name, type: 'checkbox'})
  const prevFieldValue = useRef(formik.values[name])

  // console.log('*** field:', field)

  // useEffect(() => {
  //   if (!isEqual(formik.values[name], prevFieldValue.current)) {
  //     prevFieldValue.current = formik.values[name]

  //     if (name === 'diffShippingInfo') {
  //       forEach([
  //         'shippingFirstName',
  //         'shippingMiddleName',
  //         'shippingLastName',
  //         'shippingPhone',
  //         'shippingDepartment',
  //         'shippingCity',
  //         'shippingAddress',
  //         'shippingAdditionalInfo'
  //       ], function(key) {
  //         formik.setFieldValue(key, '')
  //         formik.setFieldTouched(key, false)
  //       })
  //     }
  //   }
  // }, [name, formik])

  useEffect(() => {
    if (!isEqual(formik.values[name], prevFieldValue.current)) {
      prevFieldValue.current = formik.values[name]

      console.log('*** changed:', formik.values[name])
      handleBlur(formik)
    }
  }, [name, formik])

  return (
    <>
      <Form.Check
        // key={name + label}
        className={`${className} ${meta.touched && (meta.error ? 'is-invalid' : 'is-valid')}`}
        label={label}
        // checked={formik.values[field.name]}
        {...field}
        // onChange={e => {
        //   // console.log('*** e:', e)
        //   // console.log('*** e.currentTarget:', e.currentTarget)
        //   // console.log('*** field:', field)
        //   // console.log('*** checked:', e.currentTarget.checked)

        //   // formik.handleChange(e)
        //   // formik.setFieldValue(name, e.currentTarget.checked)

        //   console.log('*** prev:', formik.values[field.name])
        //   // formik.values[field.name] = e.currentTarget.checked

        //   // console.log('*** post:', formik.values[field.name])
        //   formik.handleChange(e)
        //   // console.log('*** formik:', formik)
        //   handleBlur(formik)
        // }}
      />
      <ErrorMessage component="div" name={name} className="Error-message Error-message--noCorrection" />
    </>
  )
}

export default CheckboxField
