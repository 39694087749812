import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
})

const firestore = app.firestore()
export const database = {
  counters: firestore.collection('counters'),
  customers: firestore.collection('customers'),
  purchases: firestore.collectionGroup('purchases'),
  quotes: firestore.collectionGroup('quotes'),
  exchanges: firestore.collectionGroup('exchanges'),
  warranties: firestore.collectionGroup('warranties'),
  returns: firestore.collectionGroup('returns'),
  ringSizes: firestore.collectionGroup('ringSizes'),
  maintenances: firestore.collectionGroup('maintenances'),
  getCurrentTimestamp: firebase.firestore.FieldValue.serverTimestamp,
  arrayUnion: firebase.firestore.FieldValue.arrayUnion
}
export const storage = app.storage()
export const auth = app.auth()
export default app
