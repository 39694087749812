import React, { useState, useEffect, useCallback } from 'react'
import phalangeTags from "../constants/phalangeTags"
import {
  Form,
  Row,
  Col,
  Button
} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faPlus
} from '@fortawesome/free-solid-svg-icons'
import RingSizeCard from "../cards/RingSizeCard"

const defaultPhalangeTags = phalangeTags.map((tag, idx) => ({
  ...tag,
  id: idx,
  active: false, stored: false, value: ''
}))

export default function RingSizeFormGroup({
  formState,
  setFormState,
  isModal,
  formik
}) {
  const handleAddCard = useCallback(() => {
    setFormState(prevState => ({
      ...prevState,
      ringSizeCards: [...prevState.ringSizeCards, {
        ...formik.initialValues.ringSizeCards[0],
        id: prevState.ringSizeCards[prevState.ringSizeCards.length - 1].id + 1
      }]
    }))
  }, [setFormState])

  return (
    <>
      {formState.ringSizeCards && formState.ringSizeCards.map(ringSizeCard => (
        <Col
          key={`ringSizeCard${ringSizeCard.id}`}
          className={`mt-3 ${!isModal ? 'p-0' : ''}`}
          lg={(!isModal) ? 12 : 6}
        >
          <RingSizeCard
            isModal={isModal}
            card={ringSizeCard}
            defaultPhalangeTags={defaultPhalangeTags}
            formState={formState}
            setFormState={setFormState}
          />
        </Col>
      ))}
      {!isModal && <Button
        className="mt-2"
        size="sm"
        variant="ng-primary"
        onClick={handleAddCard}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>}
    </>
  )
}
