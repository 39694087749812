import pieceTypes from "./pieceTypes"

const quoteFormInitialState = {
  quoteFirstName: '',
  quoteMiddleName: '',
  quoteLastName: '',
  idType: 'CC',
  idNumber: '',
  quotePhone: '',
  email: '',
  emailOption: true,
  instagramUser: '',
  pieces: [pieceTypes[0]],
  details: '',
  contactChannel: 'whatsapp',
  imageFiles: 0,
  termsAndConditions: false,
  createdAt: '',
  isMobile: false,
  counterId: ''
}

export default quoteFormInitialState